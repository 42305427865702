<template>
  <div class="px-2 py-1">
    <div class="flex w-full justify-center" v-if="loader">
      <loader></loader>
    </div>
    <div v-if="!loader">
      <!-- Header -->
      <div class="flex justify-end">
        <button
          @click="editMode"
          class="bg-dokBlue-ultra ml-2 border-0 py-2 px-2 rounded-cu text-white cursor-pointer text-base font-semibold"
        >
          <i class="fa fa-edit"></i> Éditer
        </button>
      </div>
      <div class="border-0 border-b border-solid border-gray-300">
        <div class="grid gap-4 grid-cols-4 mb-8 py-3 ">
          <div
            class="border border-solid border-gray-200 rounded-cu bg-gray-box px-2"
          >
            <div
              class="py-2 border-0 border-b border-solid border-gray-200 text-lg text-blackdok px-2 uppercase"
            >
              Groupe sanguin
            </div>
            <div class="flex flex-wrap content-center justify-center pt-5">
              <h3 class="text-3xl">
                {{ healtbook.bloodType ? healtbook.bloodType : "-" }}
              </h3>
            </div>
          </div>
          <div
            class="border border-solid border-gray-200 rounded-cu bg-gray-box px-2"
          >
            <div
              class="py-2 border-0 border-b border-solid border-gray-200 text-lg text-blackdok px-2 uppercase"
            >
              Indice Corporel
            </div>
            <div>
              <div class="flex flex-row  w-full">
                <label
                  class="mr-2 text-sm font-semibold text-gray-700 w-32 xl:w-auto"
                  >Poids:
                </label>
                <div>
                  <span>{{
                    healtbook.weight ? healtbook.weight + " kg" : "-"
                  }}</span>
                </div>
              </div>

              <div class="flex flex-row w-full">
                <label
                  class="mr-2 text-sm font-semibold text-gray-700 w-32 xl:w-auto"
                  >Taille:
                </label>
                <div>
                  <span>{{
                    healtbook.height ? healtbook.height + " cm" : "-"
                  }}</span>
                </div>
              </div>

              <div class="flex flex-row w-full">
                <label
                  class="mr-2 text-sm font-semibold text-gray-700 w-32 xl:w-auto"
                  title="L’indice de masse corporelle (IMC)"
                  >IMC:
                </label>
                <div>
                  <span class="mr-2">{{
                    healtbook.imc ? healtbook.imc : "-"
                  }}</span>
                  <i
                    @click="openModalIMC"
                    title="L’indice de masse corporelle (IMC)"
                    class="fas fa-question-circle fa-sm cursor-pointer text-gray-400"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div
            class="border border-solid border-gray-200 rounded-cu bg-gray-box px-2"
            v-if="healtbook.smoking != null"
          >
            <div
              class="py-2 border-0 border-b border-solid border-gray-200 text-lg text-blackdok px-2 uppercase"
            >
              Mode de vie
            </div>
            <div class="py-1 px-2">
              <div>
                <h4
                  class="text-base mr-2 text-blackdok capitalize font-semibold"
                >
                  Fumeur:
                  <span class="border-gray-600">{{
                    healtbook.smoking.isSmoker ? "Oui" : "Non"
                  }}</span>
                </h4>
                <div v-if="healtbook.smoking && healtbook.smoking.isSmoker">
                  Depuis:
                  <span class="font-semibold"
                    >{{
                      healtbook.smoking && healtbook.smoking.since
                        ? moment()(healtbook.smoking.since).format(
                            "DD MMM  YYYY"
                          )
                        : "-"
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            class="border border-solid border-gray-200 rounded-cu bg-gray-box px-2"
            v-if="patient.gender == 'FEMALE' && healtbook.pregnancy != null"
          >
            <div
              class="py-2 border-0 border-b border-solid border-gray-200 text-lg text-blackdok px-2 uppercase"
            >
              Maternité
            </div>
            <div
              class="py-1 px-2"
              v-if="
                patient.gender == 'FEMALE' &&
                  healtbook.pregnancy &&
                  healtbook.pregnancy.isPregnant
              "
            >
              <label
                class="text-base mr-2 text-blackdok capitalize font-semibold"
                >Enceinte:
              </label>
              <div>
                <div class="mr-2">
                  Depuis :
                  <span class="font-semibold">{{
                    healtbook.pregnancy && healtbook.pregnancy.probableStartDate
                      ? moment()(healtbook.pregnancy.probableStartDate).format(
                          "DD MMM  YYYY"
                        )
                      : "-"
                  }}</span>
                </div>
                <div class="mr-2">
                  Date prévue d'accouchement :
                  <span class="font-semibold">{{
                    healtbook.pregnancy &&
                    healtbook.pregnancy.expectedDeliveryDate
                      ? moment()(
                          healtbook.pregnancy.expectedDeliveryDate
                        ).format("DD MMM  YYYY")
                      : "-"
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Header -->

      <div
        class="grid gap-2 grid-cols-1 border-0 border-b border-solid border-gray-300 my-5 pb-5"
      >
        <div id="healtbook-vaccin">
          <h4 class="uppercase text-gray-700 text-2xl font-semibold mb-4">
            <i class="fas fa-syringe"></i> Vaccins
          </h4>
          <div class="w-full min-dheight">
            <ul
              :class="
                `grid grid-cols-1 xl:grid-cols-${healtbook.vaccins.length} gap-4`
              "
            >
              <li
                v-if="healtbook.vaccins && healtbook.vaccins.length"
                v-for="(vac, vind) in healtbook.vaccins"
                :key="vind"
                class="flex items-center"
              >
                <i class="fas fa-check mr-2 text-dokBlue-ultra"></i>
                <div class="text-base mr-2 text-blackdok font-semibold">
                  <span>{{ vac.name }}</span> <br />
                  <span class="text-base mr-2 text-gray-500 font-semibold">
                    {{
                      vac.vaccinDate && moment()(vac.vaccinDate).isValid()
                        ? moment()(vac.vaccinDate).format("D MMM YYYY")
                        : ""
                    }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        class="grid gap-2 grid-cols-1 border-0 border-b border-solid border-gray-300 pb-5 mb-5"
      >
        <div id="healtbook-allergiess">
          <h4 class="uppercase text-gray-700 text-2xl font-semibold mb-4">
            <i class="fas fa-allergies"></i> Allergies & intolérences:
          </h4>
          <div
            class="grid grid-cols-1 xl:grid-cols-4 gap-4 min-dheight"
            v-if="healtbook.allergies"
          >
            <div
              v-if="healtbook.allergies"
              v-for="(allerg, aind) in healtbook.allergies"
              :key="aind"
              class="border border-solid border-gray-200 rounded-cu bg-gray-box"
            >
              <div
                class="py-2 border-0 border-b border-solid border-gray-200 text-lg text-blackdok px-2 uppercase"
              >
                {{ allerg.category }}
              </div>
              <div class="py-1 px-2">
                <ul class="flex flex-col">
                  <li
                    v-for="(tal, talind) in allerg.allergies"
                    :key="talind"
                    class="mb-1"
                  >
                    <div>
                      <h4
                        class="text-base mr-2 text-blackdok capitalize font-semibold"
                      >
                        {{
                          tal.allergy && tal.allergy.parent
                            ? tal.allergy.parentName + " /"
                            : ""
                        }}
                        {{ tal.allergy ? tal.allergy.name : tal.allergyName }}
                      </h4>
                      <div class="text-base text-gray-700 pl-2">
                        {{ tal.moreInfo ? tal.moreInfo : "--" }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 class="uppercase text-gray-700 text-2xl font-semibold mb-4">
        <i class="fas fa-shield-virus"></i> Antécedents
      </h4>

      <div class="grid gap-2 grid-cols-1 xl:grid-cols-3">
        <div
          id="healtbook-familiaux"
          class="border border-solid border-gray-200 rounded-cu bg-gray-box"
        >
          <div
            class="py-2 border-0 border-b border-solid border-gray-200 text-lg font-semibold text-blackdok px-2"
          >
            <i class="fas fa-users"></i> Familiaux
          </div>
          <div class="py-1 px-2">
            <ul
              class="flex flex-col"
              v-if="
                healtbook.antecedentFamiliaux &&
                  healtbook.antecedentFamiliaux.length
              "
            >
              <li
                v-for="(familant, find) in healtbook.antecedentFamiliaux"
                :key="find"
                class="flex"
              >
                <div
                  class="text-base mr-2 text-blackdok font-semibold capitalize"
                >
                  {{
                    familant.antecedent && familant.antecedent.name
                      ? familant.antecedent.name
                      : familant.name
                  }}
                  :
                </div>
                <div class="text-base text-gray-700">
                  {{ familant.moreInfo ? familant.moreInfo : "--" }}
                </div>
              </li>
            </ul>
            <div
              v-if="
                healtbook.antecedentFamiliaux &&
                  !healtbook.antecedentFamiliaux.length
              "
            >
              <img src="/svg/null.svg" class="max-h-200" />
            </div>
          </div>
        </div>
        <div
          id="healtbook-chirurgicaux"
          class="border border-solid border-gray-200 rounded-cu bg-gray-box"
        >
          <div
            class="py-2 border-0 border-b border-solid border-gray-200 text-lg font-semibold text-blackdok px-2"
          >
            <i class="fas fa-heartbeat"></i> Chirurgicaux:
          </div>

          <div class="py-1 px-2">
            <ul
              class="flex flex-col"
              v-for="(chi, cind) in healtbook.antecedentChirurgicaux"
              :key="cind"
            >
              <li>
                <div
                  class="text-base mr-2 text-blackdok font-semibold capitalize"
                >
                  {{ chi.operation }} :
                </div>
                <div class="text-base text-gray-700 capitalize">
                  {{
                    moment()(chi.operationDate).isValid()
                      ? moment()(chi.operationDate).format("DD MMM  YYYY")
                      : "-"
                  }}
                </div>
              </li>
            </ul>
            <div
              v-if="
                healtbook.antecedentChirurgicaux &&
                  !healtbook.antecedentChirurgicaux.length
              "
            >
              <img src="/svg/null.svg" class="max-h-200" />
            </div>
          </div>
        </div>
        <div
          id="healtbook-medicaux"
          class="border border-solid border-gray-200 rounded-cu bg-gray-box"
        >
          <div
            class="py-2 border-0 border-b border-solid border-gray-200 text-lg font-semibold text-blackdok px-2"
          >
            <i class="fas fa-notes-medical"></i> Médicaux
          </div>
          <div class="py-1">
            <ul
              class="flex flex-col"
              v-if="
                healtbook.antecedentMedicaux &&
                  healtbook.antecedentMedicaux.length
              "
            >
              <li
                v-for="(medic, mind) in healtbook.antecedentMedicaux"
                :key="mind"
                class="flex flex-col mb-2 px-2 py-3 border-0 border-b border-solid border-gray-200"
              >
                <div class="text-base mr-2 text-blackdok font-semibold">
                  {{ medic.antecedent ? medic.antecedent.name : medic.name }} :
                </div>
                <div class="text-base text-gray-700 px-1 capitalize">
                  <b class="text-base text-gray-700 ">Depuis:</b>
                  {{
                    medic.startTreatmentDate
                      ? moment()(medic.startTreatmentDate).format(
                          "DD MMM  YYYY"
                        )
                      : "-"
                  }}
                </div>
                <div class="text-base text-gray-700 px-1">
                  {{ medic.moreInfo || "--" }}
                </div>
              </li>
            </ul>
            <div
              v-if="
                healtbook.antecedentMedicaux &&
                  !healtbook.antecedentMedicaux.length
              "
            >
              <img src="/svg/null.svg" class="max-h-200" />
            </div>
          </div>
        </div>
      </div>

      <div class="border-0 border-t border-solid border-gray-300 pt-5 my-5">
        <h4 class="uppercase text-gray-700 text-2xl font-semibold mb-4">
          <i class="fas fa-pills"></i>Traitements chroniques
        </h4>

        <div style="overflow-x: scroll">
          <table class="w-full doktable">
            <thead>
              <tr>
                <th>Nom du Aller/complément</th>
                <th>Le dosage quotidien</th>
                <th>Pour quelle pathologie?</th>
              </tr>
            </thead>

            <tr
              v-if="healtbook.medicaments"
              v-for="(medic, indx) in healtbook.medicaments"
              :key="indx"
            >
              <td>{{ medic.name }}</td>
              <td>
                <label
                  >{{ medic.doses }}
                  {{
                    $medicamentDosageUnits[medic.doseUnit]
                      ? $medicamentDosageUnits[medic.doseUnit].unity
                      : ""
                  }}
                  {{
                    medic.prises ? " - " + medic.prises + " x/ " + "jours" : ""
                  }}</label
                >
              </td>
              <td>{{ medic.moreInfo }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="flex justify-end">
        <button
          @click="editMode"
          class="bg-dokBlue-ultra ml-2 border-0 py-2 px-2 rounded-cu text-white cursor-pointer text-base font-semibold"
        >
          <i class="fa fa-edit"></i> Éditer
        </button>
      </div>
    </div>
    <modal
      :is-active="modalIMCIsOpen"
      :exit-modal="exitModalIMC"
      :no-head="true"
    >
      <div class="imc-content mb-6">
        <table class="table-auto">
          <thead>
            <tr>
              <th class="text-right text-lg">
                Indice de masse corporelle (IMC)
              </th>
              <th class="pl-0 text-lg">Interprétation (d'après l'OMS)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-right pr-4 font-bold">0 à 16.49</td>
              <td>Maigreur extrême</td>
            </tr>
            <tr>
              <td class="text-right pr-4 font-bold">16.5 à 18.49</td>
              <td>Maigreur</td>
            </tr>
            <tr>
              <td class="text-right pr-4 font-bold">18.5 à 24.99</td>
              <td>Poids santé</td>
            </tr>
            <tr>
              <td class="text-right pr-4 font-bold">25 à 29.99</td>
              <td>Surpoids</td>
            </tr>
            <tr>
              <td class="text-right pr-4 font-bold">30 à 34.99</td>
              <td>Obèse classe 1</td>
            </tr>
            <tr>
              <td class="text-right pr-4 font-bold">35 à 39</td>
              <td>Obèse classe 2</td>
            </tr>
            <tr>
              <td class="text-right pr-4 font-bold">40 et +</td>
              <td>Obésité morbide</td>
            </tr>
          </tbody>
          <p>
            Comment est calculé l'IMC :<br />
            Poids en kg / Taille en mètre² = IMC
          </p>
        </table>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
const loader = () => import("@/views/global-components/loader");
const modal = () => import("@/views/global-components/modal.vue");

export default {
  props: ["patient", "editNotAccess"],
  data() {
    return {
      healtbook: {},
      loader: true,
      modalIMCIsOpen: false
    };
  },
  async mounted() {
    const data = await this.$store.dispatch(
      "healthbook/GET_PATIENT_HEALT_BOOK",
      {
        patientId: this.patient._id
      }
    );
    if (data.ok) {
      this.healtbook = data.data;

      this.loader = false;
    }
  },
  methods: {
    moment() {
      return moment;
    },
    editMode() {
      this.$emit("edit-mode");
    },
    openModalIMC() {
      this.modalIMCIsOpen = true;
    },
    exitModalIMC() {
      this.modalIMCIsOpen = false;
    }
  },
  components: { loader, modal }
};
</script>

<style lang="scss">
.min-dheight {
  min-height: 60px;
}

.bg-gray-box {
  background-color: #fbfcfc;
}

table.doktable {
  border-collapse: collapse;
  @apply w-full;
  th {
    @apply border;
    @apply border-solid;
    @apply border-gray-100;
    @apply bg-dokBlue-ultra;
    @apply text-white;
    @apply text-center;
    @apply px-1;
    @apply py-4;
    @apply text-base;
  }

  td {
    @apply border;
    @apply border-solid;
    @apply border-gray-400;
    @apply text-blackdok;
    @apply text-center;
    @apply px-1;
    @apply py-2;
    @apply text-base;
  }
}
</style>
